<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="85px"
        :model="msgTemplate"
        :rules="ruleValidate">
        <el-form-item :label="$t('msgTemplate.content')" prop="content">
          <el-input v-model="msgTemplate.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msgTemplate.supportVars')">
          <el-table :data="msgTemplate.msgTemplateVarVos" border style="width: 100%">
            <el-table-column prop="code" label="code">
            </el-table-column>
            <el-table-column prop="name" label="name">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  const moduleName = "msgTemplates";
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        msgTemplate: {
          id: 0,
          name: "",
          content: "",
          supportVars: "",
          msgTemplateVarVos: [],
        },
        ruleValidate: {},
      };
    },
    computed: {
      title() {
        return (this.msgTemplate.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.msgTemplate");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.msgTemplate.id = id;
        if (this.msgTemplate.id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.msgTemplate.id).then(res => {
          this.msgTemplate = res.data;
          console.log(this.msgTemplate.msgTemplateVarVos);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.msgTemplate).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.msgTemplate.id);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
